@import '@/styles/_shared.scss';

:global(html.nav-is-hidden) {
  .checkout-flyout__content {
    @include breakpoint-up('md') {
      padding: 80px;
    }
  }
}

.checkout-flyout {
  .checkout-flyout__content--enter {
    transform: translate(100%);
  }
  .checkout-flyout__content--enter-active {
    transform: translate(0);
  }
  .checkout-flyout__content--enter-done {
    transform: translate(0);
  }
  .checkout-flyout__content--exit {
    transform: translate(100%);
  }
}

.checkout-flyout {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 2003;
  height: 100%;
  width: 100%;
  &.hide {
    display: none;
    .checkout-flyout__content {
      transform: translate(100%);
    }
    .checkout-flyout__overlay {
      display: none;
    }
  }
}

.checkout-flyout__close-btn {
  background-color: transparent;
  border: none;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  @include breakpoint-up('md') {
    top: 15px;
    right: 15px;
  }
  svg {
    width: 14px;
    @include breakpoint-up('sm') {
      width: 20px;
    }
    @include breakpoint-up('md') {
      width: 27px;
    }
    * {
      fill: $sitkablue;
    }
  }
}

.checkout-flyout__overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  cursor: pointer;
}

.checkout-flyout__content {
  width: 100%;
  max-width: 980px;
  position: fixed;
  right: 0;
  height: 100%;
  background-color: $alabaster2;
  padding: 50px 30px;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
  transition: 0.2s ease-in-out transform;
  transform: translate(100%);
  overflow-y: auto;
  z-index: 1001;
  @include breakpoint-up('sm') {
    padding: 50px 40px;
  }
  @include breakpoint-up('md') {
    padding: 140px 80px 80px;
  }
}
