@import '@/styles/_shared.scss';

:global(.checkout__loading-state) {
  .loader {
    border: 2px solid $sitkablue;
    border-bottom-color: $pureWhite;
    width: 52px;
    height: 52px;
  }
}

:global(.checkout__loading-state)
:global(.harvest),
:global(.pdp-flyout) {
  .loader {
    border: 2px solid $sitkablue;
    border-bottom-color: $pureWhite;
    width: 52px;
    height: 52px;
  }
}

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.loader {
  width: 26px;
  height: 26px;
  border: 2px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}