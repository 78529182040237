@import '@/styles/_shared.scss';

.shipping-progress-bar {
  margin: 5px 0;
  .message {
    margin-bottom: 5px;
    text-align: center;
    font-weight: 800;
    font-size: 20px;
    color: $sitkabluelight;

    &.whiteText {
      color: #fff;
    }
  }

  .progress-bar {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    height: 15px;

    .progress {
      height: 100%;
      background-color: $sitkabluelight;
      transition: width 0.3s ease-in-out;
    }
  }

  &.small {
    .message {
      font-size: 14px;
    }
    .progress-bar {
      height: 10px;
      .progress {
        background-color: darken($sitkabluelight, 10%);
      }
    }
  }
}
