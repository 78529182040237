@import '@/styles/_shared.scss';

.video-wrapper {
  position: relative;
  padding-bottom: 177.7%; /* 9:16, for an aspect ratio of 1:1 change to this value to 100% */

  &.landscape {
    padding-bottom: 56.25%
  }

  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
