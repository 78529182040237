@import '@/styles/_shared.scss';

:global(.checkout__main) {
  .account-form {
    :global(.input) {
      @include breakpoint-down('xs') {
        font-size: 14px;
        height: 48px;
      }
    }
  }
}

.account-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  @include breakpoint-up('sm') {
    max-width: 540px;
  }
  &.account-form--checkout {
    max-width: none;
    align-items: flex-start;
    form {
      margin: 0;
      padding: 0;
      @include breakpoint-up('sm') {
        margin: 0;
        padding: 0;
      }
    }
    .account-form-btn-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin: 24px 0;
      @include breakpoint-up('sm') {
        flex-direction: row;
      }
      button {
        margin: 0 0 10px;
        @include breakpoint-up('sm') {
          margin: 0;
        }
      }
      button:last-child {
        margin-top: 5px;
        font-size: 14px;
      }
    }
  }
  h4 {
    margin-bottom: 10px;
    @include breakpoint-up('sm') {
      margin-bottom: 30px;
    }
  }
  form {
    text-align: center;
    margin: 20px 0 30px;
    position: relative;
    width: 100%;
    @include breakpoint-up('sm') {
      margin: 50px 0;
      padding: 0 24px;
    }
    button {
      margin-top: 30px;
      @include breakpoint-up('sm') {
        margin-top: 48px;
      }
    }
  }
  p {
    margin-bottom: 12px;
    @include breakpoint-up('sm') {
      margin-bottom: 24px;
    }
    &:last-child {
      margin: 0;
    }
  }
}

.account-form__error,
.account-form__success {
  font-size: 12px;
  line-height: 1.1;
  color: $error;
  @include breakpoint-up('sm') {
    font-size: 14px;
  }
}

.account-form__success {
  color: $success;
}

.input-password {
  position: absolute;
}

.password-group {
	display: flex;
	position: relative;
  align-items: center;
}

.password-toggle-icon {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}