@import '@/styles/_shared.scss';

:global(html.nav-is-hidden) {
  .checkout-flyout__content {
    @include breakpoint-up('md') {
      padding: 80px;
    }
  }
}

.accolade-card {
  --video-player-aspect-ratio: 177.7%;

  background: #FCF9F7;
  border-radius: 12px;
  padding: 36px;

  .video-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    border-radius: 12px;
  }

  .video-wrapper {
    position: relative;
    padding-bottom: var(--video-player-aspect-ratio);

    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .accolade-info {
    display: flex;
    width: 100%;

    .thumbnail-container {
      cursor: pointer;
      position: relative;
      width: 120px;
      height: 120px;
      border-radius: 12px;
      overflow: hidden;


      &.grow {
        animation: grow 450ms 100ms ease-in forwards;
      }

      Image {
        width: 100%;
        height: 100%;
      }

      .play-button {
        position: absolute;
        right: calc(50% - 16px);
        bottom: 5%;
      }
    }

    .info {
      flex: 1 1;

      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left: 16px;
      font-family: $frank-ruhl;
      font-weight: 400;
      opacity: 100%;

      &.hide {
        animation: fadeOut 100ms 0ms ease-in forwards;
      }

      .name {
        font-size: 32px;
        margin-bottom: 8px;
      }
      .subtext {
        font-size: 16px;
      }
    }
  }

}

@keyframes grow {
  0% {
    width: 120px;
    padding-bottom: unset;
  }
  100% {
    width: 100%;
    padding-bottom: var(--video-player-aspect-ratio);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
