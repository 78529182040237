@import '@/styles/_shared.scss';

.banner {
  font-family: $bebas !important;
  padding: 30px 30px;

  &__block {
    margin: auto;
    min-height: 75px;
    @include breakpoint-up('xs') {
      min-height: 100px;
    }
    @include breakpoint-up('sm') {
      min-height: 150px;
    }
    @include breakpoint-up('md') {
      min-height: 260px;
      max-width: 1500px;
    }
  }
  &__image {
    min-width: 100%;
    min-height: 100%;
    cursor: pointer;
    img {
      border-radius: 8px;
      border: 2px solid #93a880 !important;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    gap: 16px;
    max-width: 100vw;
    overflow: hidden;
    @include breakpoint-down('sm') {
      flex-direction: column;
      align-items: center;
    }
    &--text {
      // display: flex;

      @include breakpoint-down('sm') {
        text-align: center;
      }
      h1 {
        font-size: 24px;
        color: $alabaster;
      }
      h2 {
        font-size: 24px;
        color: #5a80a2;
        font-family: $bebas !important;
      }
    }
    &.footer-margin {
      margin-top: 30px;
    }
  }
  &__image1 {
    height: 100px;
    width: 150px;
  }

  // banner__row
  &__row {
    display: flex;
    position: relative;

    &.justify-center {
      justify-content: center;
    }
  }

  // banner__text
  &__text {
    height: 100%;
    flex: 1;

    &--title {
      position: absolute;
      top: 80px;
      text-align: center;
      h2 {
        font-size: 40px;
        font-family: $bebas !important;

        @include breakpoint-down('xs') {
          font-size: 25px;
        }
      }
      @include breakpoint-down('xs') {
        position: absolute;
        top: 20px;
        text-align: left;
      }
    }

    // banner__text--inner
    &--inner {
      position: relative;

      h1 {
        margin-bottom: 12px;

        @include breakpoint-up('sm') {
          margin-bottom: 18px;
        }
      }
    }
  }

  // banner__wrap
  &__wrap {
    position: relative;

    // banner__wrap--dsktp
    &--dsktp {
      display: none;
    }

    // banner__wrap--mbl
    &--mbl {
      @include breakpoint-up('sm') {
        display: none;
      }
    }

    img {
      object-fit: cover;
    }
  }

  // Padded banner Option
  &.image--padded {
    .banner__text {
      &--inner {
        padding: 24px 28px 60px 28px;
        text-align: left;

        @include breakpoint-up('sm') {
          padding: 0 10.2% 0 0;
        }

        .subheader {
          margin-bottom: 18px;

          @include breakpoint-up('sm') {
            margin-bottom: 36px;
          }
        }
      }
    }

    .banner__wrap {
      &--mbl {
        height: 242px;
      }
    }

    .banner__wrap {
      &--dsktp {
        @include breakpoint-up('sm') {
          display: block;
          height: 792px;
          border-radius: 12px;

          img {
            border-radius: 12px;
          }
        }
      }
    }

    .banner__row {
      flex-direction: column-reverse;

      @include breakpoint-up('sm') {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        padding: 84px 4.27% 84px 6.3%;
      }
    }

    .btn-wrap {
      display: flex;
      align-items: center;
    }
  }

  .divider {
    width: 0px;
    height: 100%;
    min-height: 54px;
    margin-right: 32px;
    margin-left: 32px;
    border: 1px solid #dfdfdf77;
    @include breakpoint-down('sm') {
      display: none;
    }
  }

  .btn-wrap {
    flex-wrap: wrap;
    @include breakpoint-up('sm') {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }

    .footer-btn {
      max-width: 319px;
      min-width: 150px !important;
      margin-bottom: 12px;
      font-size: 20px;
      font-family: $bebas !important;

      @include breakpoint-up('sm') {
        max-width: 250px;
        margin-bottom: 0;
      }
      @include breakpoint-down('xxs') {
        max-width: 250px;
        margin-top: 10px;
      }
    }
  }
}
